import React, { useReducer } from "react"
import { useStaticQuery, graphql } from "gatsby"

import PostItem from "./postItem"

import cx from "classnames"
import styles from "./postList.module.scss"

const PostList = props => {
  const query = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: {
          fields: { sourceInstanceName: { eq: "posts" } }
          frontmatter: { published: { eq: true } }
        }
        sort: { fields: frontmatter___date, order: DESC }
      ) {
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              title
              date(formatString: "MMM DD, YYYY")
              featured_image {
                childImageSharp {
                  fluid(quality: 90, maxWidth: 900) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const reducer = (state, action) => {
    switch (action) {
      case "showMore":
        return state + 6
      default:
        throw new Error("Unexpected Action")
    }
  }

  const [number, dispatch] = useReducer(reducer, props.number)

  const morePosts = number < query.allMarkdownRemark.edges.length ? true : false

  const filter = query.allMarkdownRemark.edges.slice(
    props.start,
    props.number === "all" ? query.allMarkdownRemark.edges.length : number
  )

  const display = () =>
    filter.map(post => <PostItem key={post.node.id} post={post.node} />)

  return (
    <section className="wrapper section copy-width">
      <div className="flex justify-between">{display()}</div>
      <button
        className={cx(styles.button, morePosts === false && styles.disabled)}
        onClick={() => (morePosts ? dispatch("showMore") : null)}
      >
        {morePosts ? "Show more" : "No more posts!"}
      </button>
    </section>
  )
}

PostList.defaultProps = {
  number: 6,
  start: 0,
}

export default PostList
