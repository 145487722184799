import React from "react"
import { Link } from "gatsby"

import styles from "./postLogo.module.scss"

import logo from "../images/pdb-logo-min-main.svg"

const PostLogo = () => (
  <Link to="/">
    <img alt="PdB Consulting logo." className={styles.logo} src={logo} />
  </Link>
)

export default PostLogo
