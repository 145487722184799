import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

import cx from "classnames"
import styles from "./postItem.module.scss"

const PostItem = ({ post }) => {
  const image = post.frontmatter.featured_image.childImageSharp.fluid

  return (
    <Link className={cx("flex column", styles.wrapper)} to={post.fields.slug}>
      <Img className={styles.image} fluid={image} />
      <p>{post.frontmatter.date}</p>
      <h2 className={styles.title}>{post.frontmatter.title}</h2>
    </Link>
  )
}

export default PostItem
