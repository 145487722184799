import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import PostLogo from "../components/postLogo"
import PostList from "../components/postList"
import FooterSection from "../components/footerSection"

const PostsPage = () => (
  <Layout>
    <SEO title="Posts" />
    <PostLogo />
    <PostList />
    <FooterSection />
  </Layout>
)

export default PostsPage
